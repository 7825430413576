import axios from "axios";
import { useAuthStore } from "~~/store/auth";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const auth = useAuthStore()
  const defaultUrl = config.public.apiBaseURL;

  let api = axios.create({
      baseURL: defaultUrl,
      headers: {
        common: {},
      },
  });
  api.interceptors.request.use(request => {
    const token = auth.token
    if (token !== null) {
      // request.headers['Content-Type'] = 'multipart/form-data'
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  }, error => Promise.reject(error))
  return {
    provide: {
      api: api,
    },
  };
});