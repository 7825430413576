import {useLoading} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default defineNuxtPlugin((nuxtApp) => {
  const loading = useLoading({
    canCancel: false,
    color: '#CE0C2C',
  })
  return {
      provide: {
        loading: loading,
      },
    };
});