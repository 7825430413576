import { default as _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta } from "/src/pages/dispute/[...id].vue?macro=true";
import { default as _47src_47pages_47home_47index_46vueMeta } from "/src/pages/home/index.vue?macro=true";
import { default as _47src_47pages_47how_45it_45works_47index_46vueMeta } from "/src/pages/how-it-works/index.vue?macro=true";
import { default as _47src_47pages_47index_46vueMeta } from "/src/pages/index.vue?macro=true";
import { default as _47src_47pages_47middleman_45info_47index_46vueMeta } from "/src/pages/middleman-info/index.vue?macro=true";
import { default as _47src_47pages_47news_47index_46vueMeta } from "/src/pages/news/index.vue?macro=true";
import { default as _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta } from "/src/pages/news/news_details/[...id].vue?macro=true";
import { default as _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta } from "/src/pages/order/[...id].vue?macro=true";
import { default as _47src_47pages_47page_47404_46vueMeta } from "/src/pages/page/404.vue?macro=true";
import { default as _47src_47pages_47page_47about_us_46vueMeta } from "/src/pages/page/about_us.vue?macro=true";
import { default as _47src_47pages_47page_47cart_46vueMeta } from "/src/pages/page/cart.vue?macro=true";
import { default as _47src_47pages_47page_47checkout_47complete_47index_46vueMeta } from "/src/pages/page/checkout/complete/index.vue?macro=true";
import { default as _47src_47pages_47page_47checkout_47index_46vueMeta } from "/src/pages/page/checkout/index.vue?macro=true";
import { default as _47src_47pages_47page_47comming_soon_46vueMeta } from "/src/pages/page/comming_soon.vue?macro=true";
import { default as _47src_47pages_47page_47compare_47index_46vueMeta } from "/src/pages/page/compare/index.vue?macro=true";
import { default as _47src_47pages_47page_47contact_us_47index_46vueMeta } from "/src/pages/page/contact_us/index.vue?macro=true";
import { default as _47src_47pages_47page_47faq_47index_46vueMeta } from "/src/pages/page/faq/index.vue?macro=true";
import { default as _47src_47pages_47page_47forgot_password_46vueMeta } from "/src/pages/page/forgot_password.vue?macro=true";
import { default as _47src_47pages_47page_47login_46vueMeta } from "/src/pages/page/login.vue?macro=true";
import { default as _47src_47pages_47page_47order_tracking_47index_46vueMeta } from "/src/pages/page/order_tracking/index.vue?macro=true";
import { default as _47src_47pages_47page_47register_45complete_46vueMeta } from "/src/pages/page/register-complete.vue?macro=true";
import { default as _47src_47pages_47page_47register_46vueMeta } from "/src/pages/page/register.vue?macro=true";
import { default as _47src_47pages_47page_47review_47index_46vueMeta } from "/src/pages/page/review/index.vue?macro=true";
import { default as _47src_47pages_47page_47search_47index_46vueMeta } from "/src/pages/page/search/index.vue?macro=true";
import { default as _47src_47pages_47page_47verify_email_46vueMeta } from "/src/pages/page/verify_email.vue?macro=true";
import { default as _47src_47pages_47page_47wishlist_46vueMeta } from "/src/pages/page/wishlist.vue?macro=true";
import { default as _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta } from "/src/pages/product/[name]/[...id].vue?macro=true";
import { default as _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta } from "/src/pages/product/chatlist/[name]/[...id].vue?macro=true";
import { default as _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta } from "/src/pages/product/product_left_sidebar/[...id].vue?macro=true";
import { default as _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta } from "/src/pages/requests/make/[type]/[...id].vue?macro=true";
import { default as _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta } from "/src/pages/requests/view/[type]/[...id].vue?macro=true";
import { default as _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta } from "/src/pages/reviews/[...id].vue?macro=true";
import { default as _47src_47pages_47seguiti_47index_46vueMeta } from "/src/pages/seguiti/index.vue?macro=true";
import { default as _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta } from "/src/pages/seller/[...id].vue?macro=true";
import { default as _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta } from "/src/pages/seller_dashboard/add_product/index.vue?macro=true";
import { default as _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta } from "/src/pages/seller_dashboard/edit_product/[...id].vue?macro=true";
import { default as _47src_47pages_47seller_dashboard_47index_46vueMeta } from "/src/pages/seller_dashboard/index.vue?macro=true";
import { default as _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta } from "/src/pages/shop/[...id].vue?macro=true";
import { default as _47src_47pages_47terms_47index_46vueMeta } from "/src/pages/terms/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47card_47register_46vueMeta } from "/src/pages/user_dashboard/card/register.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta } from "/src/pages/user_dashboard/chat-requests/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta } from "/src/pages/user_dashboard/chat-sellers/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47chats_47index_46vueMeta } from "/src/pages/user_dashboard/chats/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47index_46vueMeta } from "/src/pages/user_dashboard/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47index_old_46vueMeta } from "/src/pages/user_dashboard/index_old.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta } from "/src/pages/user_dashboard/orders-seller/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47orders_47index_46vueMeta } from "/src/pages/user_dashboard/orders/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47products_47index_46vueMeta } from "/src/pages/user_dashboard/products/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta } from "/src/pages/user_dashboard/requests-seller/index.vue?macro=true";
import { default as _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta } from "/src/pages/user_dashboard/requests-user/index.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta } from "/src/pages/voxo_plus/cookie_bar/bottom/index.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta } from "/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta } from "/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta } from "/src/pages/voxo_plus/element_page/element_button.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta } from "/src/pages/voxo_plus/element_page/element_categories.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta } from "/src/pages/voxo_plus/element_page/element_collection_banner.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta } from "/src/pages/voxo_plus/element_page/element_deal_banner.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta } from "/src/pages/voxo_plus/element_page/element_header.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta } from "/src/pages/voxo_plus/element_page/element_home.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta } from "/src/pages/voxo_plus/element_page/element_product.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta } from "/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue?macro=true";
import { default as _47src_47pages_47voxo_plus_47search_47index_46vueMeta } from "/src/pages/voxo_plus/search/index.vue?macro=true";
export default [
  {
    name: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.name ?? "dispute-id___en",
    path: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.path ?? "/dispute/:id(.*)*",
    file: "/src/pages/dispute/[...id].vue",
    children: [],
    meta: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/dispute/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.name ?? "dispute-id___es",
    path: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/dispute/:id(.*)*",
    file: "/src/pages/dispute/[...id].vue",
    children: [],
    meta: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/dispute/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.name ?? "dispute-id___fr",
    path: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/dispute/:id(.*)*",
    file: "/src/pages/dispute/[...id].vue",
    children: [],
    meta: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47dispute_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/dispute/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47home_47index_46vueMeta?.name ?? "home___en",
    path: _47src_47pages_47home_47index_46vueMeta?.path ?? "/home",
    file: "/src/pages/home/index.vue",
    children: [],
    meta: _47src_47pages_47home_47index_46vueMeta,
    alias: _47src_47pages_47home_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47home_47index_46vueMeta?.name ?? "home___es",
    path: _47src_47pages_47home_47index_46vueMeta?.path ?? "/es/home",
    file: "/src/pages/home/index.vue",
    children: [],
    meta: _47src_47pages_47home_47index_46vueMeta,
    alias: _47src_47pages_47home_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47home_47index_46vueMeta?.name ?? "home___fr",
    path: _47src_47pages_47home_47index_46vueMeta?.path ?? "/fr/home",
    file: "/src/pages/home/index.vue",
    children: [],
    meta: _47src_47pages_47home_47index_46vueMeta,
    alias: _47src_47pages_47home_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47how_45it_45works_47index_46vueMeta?.name ?? "how-it-works___en",
    path: _47src_47pages_47how_45it_45works_47index_46vueMeta?.path ?? "/how-it-works",
    file: "/src/pages/how-it-works/index.vue",
    children: [],
    meta: _47src_47pages_47how_45it_45works_47index_46vueMeta,
    alias: _47src_47pages_47how_45it_45works_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47how_45it_45works_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/how-it-works/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47how_45it_45works_47index_46vueMeta?.name ?? "how-it-works___es",
    path: _47src_47pages_47how_45it_45works_47index_46vueMeta?.path ?? "/es/how-it-works",
    file: "/src/pages/how-it-works/index.vue",
    children: [],
    meta: _47src_47pages_47how_45it_45works_47index_46vueMeta,
    alias: _47src_47pages_47how_45it_45works_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47how_45it_45works_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/how-it-works/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47how_45it_45works_47index_46vueMeta?.name ?? "how-it-works___fr",
    path: _47src_47pages_47how_45it_45works_47index_46vueMeta?.path ?? "/fr/how-it-works",
    file: "/src/pages/how-it-works/index.vue",
    children: [],
    meta: _47src_47pages_47how_45it_45works_47index_46vueMeta,
    alias: _47src_47pages_47how_45it_45works_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47how_45it_45works_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/how-it-works/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47index_46vueMeta?.name ?? "index___en",
    path: _47src_47pages_47index_46vueMeta?.path ?? "/",
    file: "/src/pages/index.vue",
    children: [],
    meta: _47src_47pages_47index_46vueMeta,
    alias: _47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47index_46vueMeta?.name ?? "index___es",
    path: _47src_47pages_47index_46vueMeta?.path ?? "/es",
    file: "/src/pages/index.vue",
    children: [],
    meta: _47src_47pages_47index_46vueMeta,
    alias: _47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47index_46vueMeta?.name ?? "index___fr",
    path: _47src_47pages_47index_46vueMeta?.path ?? "/fr",
    file: "/src/pages/index.vue",
    children: [],
    meta: _47src_47pages_47index_46vueMeta,
    alias: _47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47middleman_45info_47index_46vueMeta?.name ?? "middleman-info___en",
    path: _47src_47pages_47middleman_45info_47index_46vueMeta?.path ?? "/middleman-info",
    file: "/src/pages/middleman-info/index.vue",
    children: [],
    meta: _47src_47pages_47middleman_45info_47index_46vueMeta,
    alias: _47src_47pages_47middleman_45info_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47middleman_45info_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/middleman-info/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47middleman_45info_47index_46vueMeta?.name ?? "middleman-info___es",
    path: _47src_47pages_47middleman_45info_47index_46vueMeta?.path ?? "/es/middleman-info",
    file: "/src/pages/middleman-info/index.vue",
    children: [],
    meta: _47src_47pages_47middleman_45info_47index_46vueMeta,
    alias: _47src_47pages_47middleman_45info_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47middleman_45info_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/middleman-info/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47middleman_45info_47index_46vueMeta?.name ?? "middleman-info___fr",
    path: _47src_47pages_47middleman_45info_47index_46vueMeta?.path ?? "/fr/middleman-info",
    file: "/src/pages/middleman-info/index.vue",
    children: [],
    meta: _47src_47pages_47middleman_45info_47index_46vueMeta,
    alias: _47src_47pages_47middleman_45info_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47middleman_45info_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/middleman-info/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47index_46vueMeta?.name ?? "news___en",
    path: _47src_47pages_47news_47index_46vueMeta?.path ?? "/news",
    file: "/src/pages/news/index.vue",
    children: [],
    meta: _47src_47pages_47news_47index_46vueMeta,
    alias: _47src_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47index_46vueMeta?.name ?? "news___es",
    path: _47src_47pages_47news_47index_46vueMeta?.path ?? "/es/news",
    file: "/src/pages/news/index.vue",
    children: [],
    meta: _47src_47pages_47news_47index_46vueMeta,
    alias: _47src_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47index_46vueMeta?.name ?? "news___fr",
    path: _47src_47pages_47news_47index_46vueMeta?.path ?? "/fr/news",
    file: "/src/pages/news/index.vue",
    children: [],
    meta: _47src_47pages_47news_47index_46vueMeta,
    alias: _47src_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.name ?? "news-news_details-id___en",
    path: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.path ?? "/news/news_details/:id(.*)*",
    file: "/src/pages/news/news_details/[...id].vue",
    children: [],
    meta: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/news_details/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.name ?? "news-news_details-id___es",
    path: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/news/news_details/:id(.*)*",
    file: "/src/pages/news/news_details/[...id].vue",
    children: [],
    meta: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/news_details/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.name ?? "news-news_details-id___fr",
    path: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/news/news_details/:id(.*)*",
    file: "/src/pages/news/news_details/[...id].vue",
    children: [],
    meta: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47news_47news_details_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/news/news_details/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.name ?? "order-id___en",
    path: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.path ?? "/order/:id(.*)*",
    file: "/src/pages/order/[...id].vue",
    children: [],
    meta: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/order/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.name ?? "order-id___es",
    path: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/order/:id(.*)*",
    file: "/src/pages/order/[...id].vue",
    children: [],
    meta: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/order/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.name ?? "order-id___fr",
    path: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/order/:id(.*)*",
    file: "/src/pages/order/[...id].vue",
    children: [],
    meta: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47order_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/order/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47404_46vueMeta?.name ?? "page-404___en",
    path: _47src_47pages_47page_47404_46vueMeta?.path ?? "/page/404",
    file: "/src/pages/page/404.vue",
    children: [],
    meta: _47src_47pages_47page_47404_46vueMeta,
    alias: _47src_47pages_47page_47404_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47404_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/404.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47404_46vueMeta?.name ?? "page-404___es",
    path: _47src_47pages_47page_47404_46vueMeta?.path ?? "/es/page/404",
    file: "/src/pages/page/404.vue",
    children: [],
    meta: _47src_47pages_47page_47404_46vueMeta,
    alias: _47src_47pages_47page_47404_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47404_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/404.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47404_46vueMeta?.name ?? "page-404___fr",
    path: _47src_47pages_47page_47404_46vueMeta?.path ?? "/fr/page/404",
    file: "/src/pages/page/404.vue",
    children: [],
    meta: _47src_47pages_47page_47404_46vueMeta,
    alias: _47src_47pages_47page_47404_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47404_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/404.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47about_us_46vueMeta?.name ?? "page-about_us___en",
    path: _47src_47pages_47page_47about_us_46vueMeta?.path ?? "/page/about_us",
    file: "/src/pages/page/about_us.vue",
    children: [],
    meta: _47src_47pages_47page_47about_us_46vueMeta,
    alias: _47src_47pages_47page_47about_us_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47about_us_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/about_us.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47about_us_46vueMeta?.name ?? "page-about_us___es",
    path: _47src_47pages_47page_47about_us_46vueMeta?.path ?? "/es/page/about_us",
    file: "/src/pages/page/about_us.vue",
    children: [],
    meta: _47src_47pages_47page_47about_us_46vueMeta,
    alias: _47src_47pages_47page_47about_us_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47about_us_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/about_us.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47about_us_46vueMeta?.name ?? "page-about_us___fr",
    path: _47src_47pages_47page_47about_us_46vueMeta?.path ?? "/fr/page/about_us",
    file: "/src/pages/page/about_us.vue",
    children: [],
    meta: _47src_47pages_47page_47about_us_46vueMeta,
    alias: _47src_47pages_47page_47about_us_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47about_us_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/about_us.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47cart_46vueMeta?.name ?? "page-cart___en",
    path: _47src_47pages_47page_47cart_46vueMeta?.path ?? "/page/cart",
    file: "/src/pages/page/cart.vue",
    children: [],
    meta: _47src_47pages_47page_47cart_46vueMeta,
    alias: _47src_47pages_47page_47cart_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47cart_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/cart.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47cart_46vueMeta?.name ?? "page-cart___es",
    path: _47src_47pages_47page_47cart_46vueMeta?.path ?? "/es/page/cart",
    file: "/src/pages/page/cart.vue",
    children: [],
    meta: _47src_47pages_47page_47cart_46vueMeta,
    alias: _47src_47pages_47page_47cart_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47cart_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/cart.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47cart_46vueMeta?.name ?? "page-cart___fr",
    path: _47src_47pages_47page_47cart_46vueMeta?.path ?? "/fr/page/cart",
    file: "/src/pages/page/cart.vue",
    children: [],
    meta: _47src_47pages_47page_47cart_46vueMeta,
    alias: _47src_47pages_47page_47cart_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47cart_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/cart.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.name ?? "page-checkout-complete___en",
    path: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.path ?? "/page/checkout/complete",
    file: "/src/pages/page/checkout/complete/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/complete/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.name ?? "page-checkout-complete___es",
    path: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.path ?? "/es/page/checkout/complete",
    file: "/src/pages/page/checkout/complete/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/complete/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.name ?? "page-checkout-complete___fr",
    path: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.path ?? "/fr/page/checkout/complete",
    file: "/src/pages/page/checkout/complete/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47complete_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/complete/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47index_46vueMeta?.name ?? "page-checkout___en",
    path: _47src_47pages_47page_47checkout_47index_46vueMeta?.path ?? "/page/checkout",
    file: "/src/pages/page/checkout/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47index_46vueMeta?.name ?? "page-checkout___es",
    path: _47src_47pages_47page_47checkout_47index_46vueMeta?.path ?? "/es/page/checkout",
    file: "/src/pages/page/checkout/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47checkout_47index_46vueMeta?.name ?? "page-checkout___fr",
    path: _47src_47pages_47page_47checkout_47index_46vueMeta?.path ?? "/fr/page/checkout",
    file: "/src/pages/page/checkout/index.vue",
    children: [],
    meta: _47src_47pages_47page_47checkout_47index_46vueMeta,
    alias: _47src_47pages_47page_47checkout_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47checkout_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47comming_soon_46vueMeta?.name ?? "page-comming_soon___en",
    path: _47src_47pages_47page_47comming_soon_46vueMeta?.path ?? "/page/comming_soon",
    file: "/src/pages/page/comming_soon.vue",
    children: [],
    meta: _47src_47pages_47page_47comming_soon_46vueMeta,
    alias: _47src_47pages_47page_47comming_soon_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47comming_soon_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/comming_soon.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47comming_soon_46vueMeta?.name ?? "page-comming_soon___es",
    path: _47src_47pages_47page_47comming_soon_46vueMeta?.path ?? "/es/page/comming_soon",
    file: "/src/pages/page/comming_soon.vue",
    children: [],
    meta: _47src_47pages_47page_47comming_soon_46vueMeta,
    alias: _47src_47pages_47page_47comming_soon_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47comming_soon_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/comming_soon.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47comming_soon_46vueMeta?.name ?? "page-comming_soon___fr",
    path: _47src_47pages_47page_47comming_soon_46vueMeta?.path ?? "/fr/page/comming_soon",
    file: "/src/pages/page/comming_soon.vue",
    children: [],
    meta: _47src_47pages_47page_47comming_soon_46vueMeta,
    alias: _47src_47pages_47page_47comming_soon_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47comming_soon_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/comming_soon.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47compare_47index_46vueMeta?.name ?? "page-compare___en",
    path: _47src_47pages_47page_47compare_47index_46vueMeta?.path ?? "/page/compare",
    file: "/src/pages/page/compare/index.vue",
    children: [],
    meta: _47src_47pages_47page_47compare_47index_46vueMeta,
    alias: _47src_47pages_47page_47compare_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47compare_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/compare/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47compare_47index_46vueMeta?.name ?? "page-compare___es",
    path: _47src_47pages_47page_47compare_47index_46vueMeta?.path ?? "/es/page/compare",
    file: "/src/pages/page/compare/index.vue",
    children: [],
    meta: _47src_47pages_47page_47compare_47index_46vueMeta,
    alias: _47src_47pages_47page_47compare_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47compare_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/compare/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47compare_47index_46vueMeta?.name ?? "page-compare___fr",
    path: _47src_47pages_47page_47compare_47index_46vueMeta?.path ?? "/fr/page/compare",
    file: "/src/pages/page/compare/index.vue",
    children: [],
    meta: _47src_47pages_47page_47compare_47index_46vueMeta,
    alias: _47src_47pages_47page_47compare_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47compare_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/compare/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47contact_us_47index_46vueMeta?.name ?? "page-contact_us___en",
    path: _47src_47pages_47page_47contact_us_47index_46vueMeta?.path ?? "/page/contact_us",
    file: "/src/pages/page/contact_us/index.vue",
    children: [],
    meta: _47src_47pages_47page_47contact_us_47index_46vueMeta,
    alias: _47src_47pages_47page_47contact_us_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47contact_us_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/contact_us/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47contact_us_47index_46vueMeta?.name ?? "page-contact_us___es",
    path: _47src_47pages_47page_47contact_us_47index_46vueMeta?.path ?? "/es/page/contact_us",
    file: "/src/pages/page/contact_us/index.vue",
    children: [],
    meta: _47src_47pages_47page_47contact_us_47index_46vueMeta,
    alias: _47src_47pages_47page_47contact_us_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47contact_us_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/contact_us/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47contact_us_47index_46vueMeta?.name ?? "page-contact_us___fr",
    path: _47src_47pages_47page_47contact_us_47index_46vueMeta?.path ?? "/fr/page/contact_us",
    file: "/src/pages/page/contact_us/index.vue",
    children: [],
    meta: _47src_47pages_47page_47contact_us_47index_46vueMeta,
    alias: _47src_47pages_47page_47contact_us_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47contact_us_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/contact_us/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47faq_47index_46vueMeta?.name ?? "page-faq___en",
    path: _47src_47pages_47page_47faq_47index_46vueMeta?.path ?? "/page/faq",
    file: "/src/pages/page/faq/index.vue",
    children: [],
    meta: _47src_47pages_47page_47faq_47index_46vueMeta,
    alias: _47src_47pages_47page_47faq_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47faq_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/faq/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47faq_47index_46vueMeta?.name ?? "page-faq___es",
    path: _47src_47pages_47page_47faq_47index_46vueMeta?.path ?? "/es/page/faq",
    file: "/src/pages/page/faq/index.vue",
    children: [],
    meta: _47src_47pages_47page_47faq_47index_46vueMeta,
    alias: _47src_47pages_47page_47faq_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47faq_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/faq/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47faq_47index_46vueMeta?.name ?? "page-faq___fr",
    path: _47src_47pages_47page_47faq_47index_46vueMeta?.path ?? "/fr/page/faq",
    file: "/src/pages/page/faq/index.vue",
    children: [],
    meta: _47src_47pages_47page_47faq_47index_46vueMeta,
    alias: _47src_47pages_47page_47faq_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47faq_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/faq/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47forgot_password_46vueMeta?.name ?? "page-forgot_password___en",
    path: _47src_47pages_47page_47forgot_password_46vueMeta?.path ?? "/page/forgot_password",
    file: "/src/pages/page/forgot_password.vue",
    children: [],
    meta: _47src_47pages_47page_47forgot_password_46vueMeta,
    alias: _47src_47pages_47page_47forgot_password_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47forgot_password_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47forgot_password_46vueMeta?.name ?? "page-forgot_password___es",
    path: _47src_47pages_47page_47forgot_password_46vueMeta?.path ?? "/es/page/forgot_password",
    file: "/src/pages/page/forgot_password.vue",
    children: [],
    meta: _47src_47pages_47page_47forgot_password_46vueMeta,
    alias: _47src_47pages_47page_47forgot_password_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47forgot_password_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47forgot_password_46vueMeta?.name ?? "page-forgot_password___fr",
    path: _47src_47pages_47page_47forgot_password_46vueMeta?.path ?? "/fr/page/forgot_password",
    file: "/src/pages/page/forgot_password.vue",
    children: [],
    meta: _47src_47pages_47page_47forgot_password_46vueMeta,
    alias: _47src_47pages_47page_47forgot_password_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47forgot_password_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47login_46vueMeta?.name ?? "page-login___en",
    path: _47src_47pages_47page_47login_46vueMeta?.path ?? "/page/login",
    file: "/src/pages/page/login.vue",
    children: [],
    meta: _47src_47pages_47page_47login_46vueMeta,
    alias: _47src_47pages_47page_47login_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47login_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/login.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47login_46vueMeta?.name ?? "page-login___es",
    path: _47src_47pages_47page_47login_46vueMeta?.path ?? "/es/page/login",
    file: "/src/pages/page/login.vue",
    children: [],
    meta: _47src_47pages_47page_47login_46vueMeta,
    alias: _47src_47pages_47page_47login_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47login_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/login.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47login_46vueMeta?.name ?? "page-login___fr",
    path: _47src_47pages_47page_47login_46vueMeta?.path ?? "/fr/page/login",
    file: "/src/pages/page/login.vue",
    children: [],
    meta: _47src_47pages_47page_47login_46vueMeta,
    alias: _47src_47pages_47page_47login_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47login_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/login.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.name ?? "page-order_tracking___en",
    path: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.path ?? "/page/order_tracking",
    file: "/src/pages/page/order_tracking/index.vue",
    children: [],
    meta: _47src_47pages_47page_47order_tracking_47index_46vueMeta,
    alias: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/order_tracking/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.name ?? "page-order_tracking___es",
    path: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.path ?? "/es/page/order_tracking",
    file: "/src/pages/page/order_tracking/index.vue",
    children: [],
    meta: _47src_47pages_47page_47order_tracking_47index_46vueMeta,
    alias: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/order_tracking/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.name ?? "page-order_tracking___fr",
    path: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.path ?? "/fr/page/order_tracking",
    file: "/src/pages/page/order_tracking/index.vue",
    children: [],
    meta: _47src_47pages_47page_47order_tracking_47index_46vueMeta,
    alias: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47order_tracking_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/order_tracking/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_45complete_46vueMeta?.name ?? "page-register-complete___en",
    path: _47src_47pages_47page_47register_45complete_46vueMeta?.path ?? "/page/register-complete",
    file: "/src/pages/page/register-complete.vue",
    children: [],
    meta: _47src_47pages_47page_47register_45complete_46vueMeta,
    alias: _47src_47pages_47page_47register_45complete_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_45complete_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register-complete.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_45complete_46vueMeta?.name ?? "page-register-complete___es",
    path: _47src_47pages_47page_47register_45complete_46vueMeta?.path ?? "/es/page/register-complete",
    file: "/src/pages/page/register-complete.vue",
    children: [],
    meta: _47src_47pages_47page_47register_45complete_46vueMeta,
    alias: _47src_47pages_47page_47register_45complete_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_45complete_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register-complete.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_45complete_46vueMeta?.name ?? "page-register-complete___fr",
    path: _47src_47pages_47page_47register_45complete_46vueMeta?.path ?? "/fr/page/register-complete",
    file: "/src/pages/page/register-complete.vue",
    children: [],
    meta: _47src_47pages_47page_47register_45complete_46vueMeta,
    alias: _47src_47pages_47page_47register_45complete_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_45complete_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register-complete.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_46vueMeta?.name ?? "page-register___en",
    path: _47src_47pages_47page_47register_46vueMeta?.path ?? "/page/register",
    file: "/src/pages/page/register.vue",
    children: [],
    meta: _47src_47pages_47page_47register_46vueMeta,
    alias: _47src_47pages_47page_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_46vueMeta?.name ?? "page-register___es",
    path: _47src_47pages_47page_47register_46vueMeta?.path ?? "/es/page/register",
    file: "/src/pages/page/register.vue",
    children: [],
    meta: _47src_47pages_47page_47register_46vueMeta,
    alias: _47src_47pages_47page_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47register_46vueMeta?.name ?? "page-register___fr",
    path: _47src_47pages_47page_47register_46vueMeta?.path ?? "/fr/page/register",
    file: "/src/pages/page/register.vue",
    children: [],
    meta: _47src_47pages_47page_47register_46vueMeta,
    alias: _47src_47pages_47page_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47review_47index_46vueMeta?.name ?? "page-review___en",
    path: _47src_47pages_47page_47review_47index_46vueMeta?.path ?? "/page/review",
    file: "/src/pages/page/review/index.vue",
    children: [],
    meta: _47src_47pages_47page_47review_47index_46vueMeta,
    alias: _47src_47pages_47page_47review_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47review_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/review/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47review_47index_46vueMeta?.name ?? "page-review___es",
    path: _47src_47pages_47page_47review_47index_46vueMeta?.path ?? "/es/page/review",
    file: "/src/pages/page/review/index.vue",
    children: [],
    meta: _47src_47pages_47page_47review_47index_46vueMeta,
    alias: _47src_47pages_47page_47review_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47review_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/review/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47review_47index_46vueMeta?.name ?? "page-review___fr",
    path: _47src_47pages_47page_47review_47index_46vueMeta?.path ?? "/fr/page/review",
    file: "/src/pages/page/review/index.vue",
    children: [],
    meta: _47src_47pages_47page_47review_47index_46vueMeta,
    alias: _47src_47pages_47page_47review_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47review_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/review/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47search_47index_46vueMeta?.name ?? "page-search___en",
    path: _47src_47pages_47page_47search_47index_46vueMeta?.path ?? "/page/search",
    file: "/src/pages/page/search/index.vue",
    children: [],
    meta: _47src_47pages_47page_47search_47index_46vueMeta,
    alias: _47src_47pages_47page_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47search_47index_46vueMeta?.name ?? "page-search___es",
    path: _47src_47pages_47page_47search_47index_46vueMeta?.path ?? "/es/page/search",
    file: "/src/pages/page/search/index.vue",
    children: [],
    meta: _47src_47pages_47page_47search_47index_46vueMeta,
    alias: _47src_47pages_47page_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47search_47index_46vueMeta?.name ?? "page-search___fr",
    path: _47src_47pages_47page_47search_47index_46vueMeta?.path ?? "/fr/page/search",
    file: "/src/pages/page/search/index.vue",
    children: [],
    meta: _47src_47pages_47page_47search_47index_46vueMeta,
    alias: _47src_47pages_47page_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47verify_email_46vueMeta?.name ?? "page-verify_email___en",
    path: _47src_47pages_47page_47verify_email_46vueMeta?.path ?? "/page/verify_email",
    file: "/src/pages/page/verify_email.vue",
    children: [],
    meta: _47src_47pages_47page_47verify_email_46vueMeta,
    alias: _47src_47pages_47page_47verify_email_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47verify_email_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/verify_email.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47verify_email_46vueMeta?.name ?? "page-verify_email___es",
    path: _47src_47pages_47page_47verify_email_46vueMeta?.path ?? "/es/page/verify_email",
    file: "/src/pages/page/verify_email.vue",
    children: [],
    meta: _47src_47pages_47page_47verify_email_46vueMeta,
    alias: _47src_47pages_47page_47verify_email_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47verify_email_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/verify_email.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47verify_email_46vueMeta?.name ?? "page-verify_email___fr",
    path: _47src_47pages_47page_47verify_email_46vueMeta?.path ?? "/fr/page/verify_email",
    file: "/src/pages/page/verify_email.vue",
    children: [],
    meta: _47src_47pages_47page_47verify_email_46vueMeta,
    alias: _47src_47pages_47page_47verify_email_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47verify_email_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/verify_email.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47wishlist_46vueMeta?.name ?? "page-wishlist___en",
    path: _47src_47pages_47page_47wishlist_46vueMeta?.path ?? "/page/wishlist",
    file: "/src/pages/page/wishlist.vue",
    children: [],
    meta: _47src_47pages_47page_47wishlist_46vueMeta,
    alias: _47src_47pages_47page_47wishlist_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47wishlist_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/wishlist.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47wishlist_46vueMeta?.name ?? "page-wishlist___es",
    path: _47src_47pages_47page_47wishlist_46vueMeta?.path ?? "/es/page/wishlist",
    file: "/src/pages/page/wishlist.vue",
    children: [],
    meta: _47src_47pages_47page_47wishlist_46vueMeta,
    alias: _47src_47pages_47page_47wishlist_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47wishlist_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/wishlist.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47page_47wishlist_46vueMeta?.name ?? "page-wishlist___fr",
    path: _47src_47pages_47page_47wishlist_46vueMeta?.path ?? "/fr/page/wishlist",
    file: "/src/pages/page/wishlist.vue",
    children: [],
    meta: _47src_47pages_47page_47wishlist_46vueMeta,
    alias: _47src_47pages_47page_47wishlist_46vueMeta?.alias || [],
    redirect: _47src_47pages_47page_47wishlist_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/page/wishlist.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-name-id___en",
    path: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/product/:name/:id(.*)*",
    file: "/src/pages/product/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-name-id___es",
    path: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/product/:name/:id(.*)*",
    file: "/src/pages/product/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-name-id___fr",
    path: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/product/:name/:id(.*)*",
    file: "/src/pages/product/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-chatlist-name-id___en",
    path: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/product/chatlist/:name/:id(.*)*",
    file: "/src/pages/product/chatlist/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/chatlist/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-chatlist-name-id___es",
    path: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/product/chatlist/:name/:id(.*)*",
    file: "/src/pages/product/chatlist/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/chatlist/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.name ?? "product-chatlist-name-id___fr",
    path: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/product/chatlist/:name/:id(.*)*",
    file: "/src/pages/product/chatlist/[name]/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47chatlist_47_91name_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/chatlist/[name]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.name ?? "product-product_left_sidebar-id___en",
    path: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.path ?? "/product/product_left_sidebar/:id(.*)*",
    file: "/src/pages/product/product_left_sidebar/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/product_left_sidebar/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.name ?? "product-product_left_sidebar-id___es",
    path: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/product/product_left_sidebar/:id(.*)*",
    file: "/src/pages/product/product_left_sidebar/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/product_left_sidebar/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.name ?? "product-product_left_sidebar-id___fr",
    path: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/product/product_left_sidebar/:id(.*)*",
    file: "/src/pages/product/product_left_sidebar/[...id].vue",
    children: [],
    meta: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47product_47product_left_sidebar_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/product/product_left_sidebar/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-make-type-id___en",
    path: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/requests/make/:type/:id(.*)*",
    file: "/src/pages/requests/make/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/make/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-make-type-id___es",
    path: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/requests/make/:type/:id(.*)*",
    file: "/src/pages/requests/make/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/make/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-make-type-id___fr",
    path: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/requests/make/:type/:id(.*)*",
    file: "/src/pages/requests/make/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47make_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/make/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-view-type-id___en",
    path: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/requests/view/:type/:id(.*)*",
    file: "/src/pages/requests/view/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/view/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-view-type-id___es",
    path: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/requests/view/:type/:id(.*)*",
    file: "/src/pages/requests/view/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/view/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.name ?? "requests-view-type-id___fr",
    path: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/requests/view/:type/:id(.*)*",
    file: "/src/pages/requests/view/[type]/[...id].vue",
    children: [],
    meta: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47requests_47view_47_91type_93_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/requests/view/[type]/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.name ?? "reviews-id___en",
    path: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.path ?? "/reviews/:id(.*)*",
    file: "/src/pages/reviews/[...id].vue",
    children: [],
    meta: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/reviews/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.name ?? "reviews-id___es",
    path: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/reviews/:id(.*)*",
    file: "/src/pages/reviews/[...id].vue",
    children: [],
    meta: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/reviews/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.name ?? "reviews-id___fr",
    path: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/reviews/:id(.*)*",
    file: "/src/pages/reviews/[...id].vue",
    children: [],
    meta: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47reviews_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/reviews/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seguiti_47index_46vueMeta?.name ?? "seguiti___en",
    path: _47src_47pages_47seguiti_47index_46vueMeta?.path ?? "/seguiti",
    file: "/src/pages/seguiti/index.vue",
    children: [],
    meta: _47src_47pages_47seguiti_47index_46vueMeta,
    alias: _47src_47pages_47seguiti_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seguiti_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seguiti/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seguiti_47index_46vueMeta?.name ?? "seguiti___es",
    path: _47src_47pages_47seguiti_47index_46vueMeta?.path ?? "/es/seguiti",
    file: "/src/pages/seguiti/index.vue",
    children: [],
    meta: _47src_47pages_47seguiti_47index_46vueMeta,
    alias: _47src_47pages_47seguiti_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seguiti_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seguiti/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seguiti_47index_46vueMeta?.name ?? "seguiti___fr",
    path: _47src_47pages_47seguiti_47index_46vueMeta?.path ?? "/fr/seguiti",
    file: "/src/pages/seguiti/index.vue",
    children: [],
    meta: _47src_47pages_47seguiti_47index_46vueMeta,
    alias: _47src_47pages_47seguiti_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seguiti_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seguiti/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.name ?? "seller-id___en",
    path: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.path ?? "/seller/:id(.*)*",
    file: "/src/pages/seller/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.name ?? "seller-id___es",
    path: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/seller/:id(.*)*",
    file: "/src/pages/seller/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.name ?? "seller-id___fr",
    path: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/seller/:id(.*)*",
    file: "/src/pages/seller/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.name ?? "seller_dashboard-add_product___en",
    path: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.path ?? "/seller_dashboard/add_product",
    file: "/src/pages/seller_dashboard/add_product/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/add_product/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.name ?? "seller_dashboard-add_product___es",
    path: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.path ?? "/es/seller_dashboard/add_product",
    file: "/src/pages/seller_dashboard/add_product/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/add_product/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.name ?? "seller_dashboard-add_product___fr",
    path: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.path ?? "/fr/seller_dashboard/add_product",
    file: "/src/pages/seller_dashboard/add_product/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47add_product_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/add_product/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.name ?? "seller_dashboard-edit_product-id___en",
    path: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.path ?? "/seller_dashboard/edit_product/:id(.*)*",
    file: "/src/pages/seller_dashboard/edit_product/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/edit_product/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.name ?? "seller_dashboard-edit_product-id___es",
    path: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/seller_dashboard/edit_product/:id(.*)*",
    file: "/src/pages/seller_dashboard/edit_product/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/edit_product/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.name ?? "seller_dashboard-edit_product-id___fr",
    path: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/seller_dashboard/edit_product/:id(.*)*",
    file: "/src/pages/seller_dashboard/edit_product/[...id].vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47edit_product_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/edit_product/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47index_46vueMeta?.name ?? "seller_dashboard___en",
    path: _47src_47pages_47seller_dashboard_47index_46vueMeta?.path ?? "/seller_dashboard",
    file: "/src/pages/seller_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47index_46vueMeta?.name ?? "seller_dashboard___es",
    path: _47src_47pages_47seller_dashboard_47index_46vueMeta?.path ?? "/es/seller_dashboard",
    file: "/src/pages/seller_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47seller_dashboard_47index_46vueMeta?.name ?? "seller_dashboard___fr",
    path: _47src_47pages_47seller_dashboard_47index_46vueMeta?.path ?? "/fr/seller_dashboard",
    file: "/src/pages/seller_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47seller_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47seller_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47seller_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/seller_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.name ?? "shop-id___en",
    path: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.path ?? "/shop/:id(.*)*",
    file: "/src/pages/shop/[...id].vue",
    children: [],
    meta: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.name ?? "shop-id___es",
    path: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.path ?? "/es/shop/:id(.*)*",
    file: "/src/pages/shop/[...id].vue",
    children: [],
    meta: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.name ?? "shop-id___fr",
    path: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.path ?? "/fr/shop/:id(.*)*",
    file: "/src/pages/shop/[...id].vue",
    children: [],
    meta: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta,
    alias: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.alias || [],
    redirect: _47src_47pages_47shop_47_91_46_46_46id_93_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/[...id].vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47terms_47index_46vueMeta?.name ?? "terms___en",
    path: _47src_47pages_47terms_47index_46vueMeta?.path ?? "/terms",
    file: "/src/pages/terms/index.vue",
    children: [],
    meta: _47src_47pages_47terms_47index_46vueMeta,
    alias: _47src_47pages_47terms_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47terms_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47terms_47index_46vueMeta?.name ?? "terms___es",
    path: _47src_47pages_47terms_47index_46vueMeta?.path ?? "/es/terms",
    file: "/src/pages/terms/index.vue",
    children: [],
    meta: _47src_47pages_47terms_47index_46vueMeta,
    alias: _47src_47pages_47terms_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47terms_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47terms_47index_46vueMeta?.name ?? "terms___fr",
    path: _47src_47pages_47terms_47index_46vueMeta?.path ?? "/fr/terms",
    file: "/src/pages/terms/index.vue",
    children: [],
    meta: _47src_47pages_47terms_47index_46vueMeta,
    alias: _47src_47pages_47terms_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47terms_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.name ?? "user_dashboard-card-register___en",
    path: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.path ?? "/user_dashboard/card/register",
    file: "/src/pages/user_dashboard/card/register.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47card_47register_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/card/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.name ?? "user_dashboard-card-register___es",
    path: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.path ?? "/es/user_dashboard/card/register",
    file: "/src/pages/user_dashboard/card/register.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47card_47register_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/card/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.name ?? "user_dashboard-card-register___fr",
    path: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.path ?? "/fr/user_dashboard/card/register",
    file: "/src/pages/user_dashboard/card/register.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47card_47register_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47card_47register_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/card/register.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.name ?? "user_dashboard-chat-requests___en",
    path: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.path ?? "/user_dashboard/chat-requests",
    file: "/src/pages/user_dashboard/chat-requests/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-requests/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.name ?? "user_dashboard-chat-requests___es",
    path: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.path ?? "/es/user_dashboard/chat-requests",
    file: "/src/pages/user_dashboard/chat-requests/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-requests/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.name ?? "user_dashboard-chat-requests___fr",
    path: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.path ?? "/fr/user_dashboard/chat-requests",
    file: "/src/pages/user_dashboard/chat-requests/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45requests_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-requests/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.name ?? "user_dashboard-chat-sellers___en",
    path: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.path ?? "/user_dashboard/chat-sellers",
    file: "/src/pages/user_dashboard/chat-sellers/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-sellers/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.name ?? "user_dashboard-chat-sellers___es",
    path: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.path ?? "/es/user_dashboard/chat-sellers",
    file: "/src/pages/user_dashboard/chat-sellers/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-sellers/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.name ?? "user_dashboard-chat-sellers___fr",
    path: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.path ?? "/fr/user_dashboard/chat-sellers",
    file: "/src/pages/user_dashboard/chat-sellers/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chat_45sellers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chat-sellers/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.name ?? "user_dashboard-chats___en",
    path: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.path ?? "/user_dashboard/chats",
    file: "/src/pages/user_dashboard/chats/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chats/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.name ?? "user_dashboard-chats___es",
    path: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.path ?? "/es/user_dashboard/chats",
    file: "/src/pages/user_dashboard/chats/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chats/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.name ?? "user_dashboard-chats___fr",
    path: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.path ?? "/fr/user_dashboard/chats",
    file: "/src/pages/user_dashboard/chats/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47chats_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/chats/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_46vueMeta?.name ?? "user_dashboard___en",
    path: _47src_47pages_47user_dashboard_47index_46vueMeta?.path ?? "/user_dashboard",
    file: "/src/pages/user_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_46vueMeta?.name ?? "user_dashboard___es",
    path: _47src_47pages_47user_dashboard_47index_46vueMeta?.path ?? "/es/user_dashboard",
    file: "/src/pages/user_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_46vueMeta?.name ?? "user_dashboard___fr",
    path: _47src_47pages_47user_dashboard_47index_46vueMeta?.path ?? "/fr/user_dashboard",
    file: "/src/pages/user_dashboard/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.name ?? "user_dashboard-index_old___en",
    path: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.path ?? "/user_dashboard/index_old",
    file: "/src/pages/user_dashboard/index_old.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_old_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index_old.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.name ?? "user_dashboard-index_old___es",
    path: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.path ?? "/es/user_dashboard/index_old",
    file: "/src/pages/user_dashboard/index_old.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_old_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index_old.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.name ?? "user_dashboard-index_old___fr",
    path: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.path ?? "/fr/user_dashboard/index_old",
    file: "/src/pages/user_dashboard/index_old.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47index_old_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47index_old_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/index_old.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.name ?? "user_dashboard-orders-seller___en",
    path: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.path ?? "/user_dashboard/orders-seller",
    file: "/src/pages/user_dashboard/orders-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.name ?? "user_dashboard-orders-seller___es",
    path: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.path ?? "/es/user_dashboard/orders-seller",
    file: "/src/pages/user_dashboard/orders-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.name ?? "user_dashboard-orders-seller___fr",
    path: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.path ?? "/fr/user_dashboard/orders-seller",
    file: "/src/pages/user_dashboard/orders-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.name ?? "user_dashboard-orders___en",
    path: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.path ?? "/user_dashboard/orders",
    file: "/src/pages/user_dashboard/orders/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.name ?? "user_dashboard-orders___es",
    path: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.path ?? "/es/user_dashboard/orders",
    file: "/src/pages/user_dashboard/orders/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.name ?? "user_dashboard-orders___fr",
    path: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.path ?? "/fr/user_dashboard/orders",
    file: "/src/pages/user_dashboard/orders/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.name ?? "user_dashboard-products___en",
    path: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.path ?? "/user_dashboard/products",
    file: "/src/pages/user_dashboard/products/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47products_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.name ?? "user_dashboard-products___es",
    path: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.path ?? "/es/user_dashboard/products",
    file: "/src/pages/user_dashboard/products/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47products_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.name ?? "user_dashboard-products___fr",
    path: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.path ?? "/fr/user_dashboard/products",
    file: "/src/pages/user_dashboard/products/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47products_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47products_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/products/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.name ?? "user_dashboard-requests-seller___en",
    path: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.path ?? "/user_dashboard/requests-seller",
    file: "/src/pages/user_dashboard/requests-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.name ?? "user_dashboard-requests-seller___es",
    path: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.path ?? "/es/user_dashboard/requests-seller",
    file: "/src/pages/user_dashboard/requests-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.name ?? "user_dashboard-requests-seller___fr",
    path: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.path ?? "/fr/user_dashboard/requests-seller",
    file: "/src/pages/user_dashboard/requests-seller/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45seller_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-seller/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.name ?? "user_dashboard-requests-user___en",
    path: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.path ?? "/user_dashboard/requests-user",
    file: "/src/pages/user_dashboard/requests-user/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-user/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.name ?? "user_dashboard-requests-user___es",
    path: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.path ?? "/es/user_dashboard/requests-user",
    file: "/src/pages/user_dashboard/requests-user/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-user/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.name ?? "user_dashboard-requests-user___fr",
    path: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.path ?? "/fr/user_dashboard/requests-user",
    file: "/src/pages/user_dashboard/requests-user/index.vue",
    children: [],
    meta: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta,
    alias: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47user_dashboard_47requests_45user_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/user_dashboard/requests-user/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom___en",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.path ?? "/voxo_plus/cookie_bar/bottom",
    file: "/src/pages/voxo_plus/cookie_bar/bottom/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom___es",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.path ?? "/es/voxo_plus/cookie_bar/bottom",
    file: "/src/pages/voxo_plus/cookie_bar/bottom/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom___fr",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.path ?? "/fr/voxo_plus/cookie_bar/bottom",
    file: "/src/pages/voxo_plus/cookie_bar/bottom/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_left___en",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.path ?? "/voxo_plus/cookie_bar/bottom_left",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_left___es",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.path ?? "/es/voxo_plus/cookie_bar/bottom_left",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_left___fr",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.path ?? "/fr/voxo_plus/cookie_bar/bottom_left",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_left_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_left/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_right___en",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.path ?? "/voxo_plus/cookie_bar/bottom_right",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_right___es",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.path ?? "/es/voxo_plus/cookie_bar/bottom_right",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.name ?? "voxo_plus-cookie_bar-bottom_right___fr",
    path: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.path ?? "/fr/voxo_plus/cookie_bar/bottom_right",
    file: "/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47cookie_bar_47bottom_right_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/cookie_bar/bottom_right/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.name ?? "voxo_plus-element_page-element_button___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.path ?? "/voxo_plus/element_page/element_button",
    file: "/src/pages/voxo_plus/element_page/element_button.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_button.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.name ?? "voxo_plus-element_page-element_button___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_button",
    file: "/src/pages/voxo_plus/element_page/element_button.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_button.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.name ?? "voxo_plus-element_page-element_button___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_button",
    file: "/src/pages/voxo_plus/element_page/element_button.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_button_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_button.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.name ?? "voxo_plus-element_page-element_categories___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.path ?? "/voxo_plus/element_page/element_categories",
    file: "/src/pages/voxo_plus/element_page/element_categories.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_categories.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.name ?? "voxo_plus-element_page-element_categories___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_categories",
    file: "/src/pages/voxo_plus/element_page/element_categories.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_categories.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.name ?? "voxo_plus-element_page-element_categories___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_categories",
    file: "/src/pages/voxo_plus/element_page/element_categories.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_categories_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_categories.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_collection_banner___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.path ?? "/voxo_plus/element_page/element_collection_banner",
    file: "/src/pages/voxo_plus/element_page/element_collection_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_collection_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_collection_banner___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_collection_banner",
    file: "/src/pages/voxo_plus/element_page/element_collection_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_collection_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_collection_banner___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_collection_banner",
    file: "/src/pages/voxo_plus/element_page/element_collection_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_collection_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_collection_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_deal_banner___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.path ?? "/voxo_plus/element_page/element_deal_banner",
    file: "/src/pages/voxo_plus/element_page/element_deal_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_deal_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_deal_banner___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_deal_banner",
    file: "/src/pages/voxo_plus/element_page/element_deal_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_deal_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.name ?? "voxo_plus-element_page-element_deal_banner___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_deal_banner",
    file: "/src/pages/voxo_plus/element_page/element_deal_banner.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_deal_banner_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_deal_banner.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.name ?? "voxo_plus-element_page-element_header___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.path ?? "/voxo_plus/element_page/element_header",
    file: "/src/pages/voxo_plus/element_page/element_header.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_header.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.name ?? "voxo_plus-element_page-element_header___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_header",
    file: "/src/pages/voxo_plus/element_page/element_header.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_header.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.name ?? "voxo_plus-element_page-element_header___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_header",
    file: "/src/pages/voxo_plus/element_page/element_header.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_header_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_header.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.name ?? "voxo_plus-element_page-element_home___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.path ?? "/voxo_plus/element_page/element_home",
    file: "/src/pages/voxo_plus/element_page/element_home.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_home.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.name ?? "voxo_plus-element_page-element_home___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_home",
    file: "/src/pages/voxo_plus/element_page/element_home.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_home.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.name ?? "voxo_plus-element_page-element_home___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_home",
    file: "/src/pages/voxo_plus/element_page/element_home.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_home_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_home.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.name ?? "voxo_plus-element_page-element_product___en",
    path: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.path ?? "/voxo_plus/element_page/element_product",
    file: "/src/pages/voxo_plus/element_page/element_product.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_product.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.name ?? "voxo_plus-element_page-element_product___es",
    path: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.path ?? "/es/voxo_plus/element_page/element_product",
    file: "/src/pages/voxo_plus/element_page/element_product.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_product.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.name ?? "voxo_plus-element_page-element_product___fr",
    path: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.path ?? "/fr/voxo_plus/element_page/element_product",
    file: "/src/pages/voxo_plus/element_page/element_product.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47element_page_47element_product_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/element_page/element_product.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_grid___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_2_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_grid___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_2_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_grid___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_2_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_masonary___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_2_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_masonary___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_2_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_2_masonary___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_2_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_2_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_2_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_grid___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_3_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_grid___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_3_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_grid___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_3_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_masonary___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_3_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_masonary___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_3_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_3_masonary___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_3_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_3_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_3_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_grid___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_4_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_grid___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_4_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_grid___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_4_grid",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_grid_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_grid.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_masonary___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_4_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_masonary___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_4_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_4_masonary___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_4_masonary",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_4_masonary_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_4_masonary.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_no_space___en",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.path ?? "/voxo_plus/portfolio_page/portfolio_no_space",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_no_space___es",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.path ?? "/es/voxo_plus/portfolio_page/portfolio_no_space",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.name ?? "voxo_plus-portfolio_page-portfolio_no_space___fr",
    path: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.path ?? "/fr/voxo_plus/portfolio_page/portfolio_no_space",
    file: "/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47portfolio_page_47portfolio_no_space_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/portfolio_page/portfolio_no_space.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.name ?? "voxo_plus-search___en",
    path: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.path ?? "/voxo_plus/search",
    file: "/src/pages/voxo_plus/search/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47search_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.name ?? "voxo_plus-search___es",
    path: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.path ?? "/es/voxo_plus/search",
    file: "/src/pages/voxo_plus/search/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47search_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/search/index.vue").then(m => m.default || m)
  },
  {
    name: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.name ?? "voxo_plus-search___fr",
    path: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.path ?? "/fr/voxo_plus/search",
    file: "/src/pages/voxo_plus/search/index.vue",
    children: [],
    meta: _47src_47pages_47voxo_plus_47search_47index_46vueMeta,
    alias: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.alias || [],
    redirect: _47src_47pages_47voxo_plus_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/src/pages/voxo_plus/search/index.vue").then(m => m.default || m)
  }
]