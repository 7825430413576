<template>
  <NuxtLayout>
    <NuxtLoadingIndicator :height="5" color="#DBD93C"/> 
<Head>
  <Title>Acktag</Title>
</Head>
<NuxtPage />
</NuxtLayout>
</template>
<script>
import { mapActions } from 'pinia';
import { useProductStore } from './store/products';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useAuthStore } from '~~/store/auth';
export default{
  methods: {
    ...mapActions(useProductStore, ['initProducts'])
  },
  created() {
    const productStore = useProductStore()
    try {
      productStore.initProducts()
    } catch (error) {
      console.log(error)
    }
    setTimeout(() => {
      const auth = useAuthStore()
      
      if(auth.isLoggedIn) {
        if(auth.unreadCount > 0) {
          const badges = document.querySelectorAll('.chat-badge')
          badges.forEach((x) => x.classList.remove('d-none'))
        }
        window.Pusher = Pusher
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: useRuntimeConfig().chatKey,
          wsHost: useRuntimeConfig().chatHost ,
          wssHost: useRuntimeConfig().chatHost ,
          httpHost: useRuntimeConfig().chatHost,
          wsPort: useRuntimeConfig().chatPort,
          wssPort: useRuntimeConfig().chatPort,
          forceTLS: useRuntimeConfig().chatTLS,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          auth: {
              headers: {'X-XSRF-TOKEN': auth.token,
                        'Accept': 'application/json',
              },
          },
          authorizer: (channel, options) => {
            return {
              authorize: (socketId, callback) => {

                this.$api.post('/api/broadcasting/auth', {socket_id: socketId, channel_name: channel.name}, {baseURL: useRuntimeConfig().baseURL, headers: {'X-XSRF-TOKEN': auth.token}, credentials: 'include'})
                .then((response) => {
                    console.log(response)
                    callback(false, response.data)
                }).catch(error => {
                  callback(true, error)

                })
              }
            };
          },
        })
        window.Echo.private(`message.notif.${auth.user.id}`)
        .listen('.App\\Events\\MessageNotif', (e) => {
          this.$toast.success(`${e.notifText} da ${e.from.name}:${e.message.length > 20 ? e.message.substr(0,20) + '...' : e.message}`)
          const badges = document.querySelectorAll('.chat-badge')
          badges.forEach((x) => x.classList.remove('d-none'))
        })
      }
      
    }, 1E3)
    
  }
}
</script>
<style>
@import url(/css/style.css);
</style>