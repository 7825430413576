import { useCartStore } from "~~/store/cart";
import { useClickStore } from "~~/store/clickEvents";
import { useCompareStore } from "~~/store/compare";
import { useProductStore } from "~~/store/products";
import { useAuthStore } from "~~/store/auth";
export default{
  methods: {
    async addToWishlist(product) {
      const auth = useAuthStore()
      if(auth.isLoggedIn) {
        useNuxtApp().$api.post(`/products/addWishlist/${product.id}`).then( async (res) => {
          var isPresentInWishlist = false;
          await (useProductStore().addToWishlist(product)).then((res) => {
              isPresentInWishlist = res;
            });
          if (isPresentInWishlist)
          useClickStore().toggleSuccessfulModal( {image: product.images[0].src,
            message: "Questo prodotto è già nella Wishlist",
            link: useRuntimeConfig().public.const.wishlistPagePath,});
          
          else
          useClickStore().toggleSuccessfulModal({
            image: product.images[0].src,
            message: "Aggiunto alla Wishlist",
            link: useRuntimeConfig().public.const.wishlistPagePath,
          })
        }).catch((error) => {
          useNuxtApp().$toast.error(error.response.data.errors)
        })
      } else {
        var isPresentInWishlist = false;
          await (useProductStore().addToWishlist(product)).then((res) => {
              isPresentInWishlist = res;
            });
          if (isPresentInWishlist)
          useClickStore().toggleSuccessfulModal( {image: product.images[0].src,
            message: "Questo prodotto è già nella Wishlist",
            link: useRuntimeConfig().public.const.wishlistPagePath,});
          
          else
          useClickStore().toggleSuccessfulModal({
            image: product.images[0].src,
            message: "Aggiunto alla Wishlist",
            link: useRuntimeConfig().public.const.wishlistPagePath,
          })
      }
      
      
    },
    toggleCartModal(product) {
      var item = { product: product, quantity: 1 };
      useClickStore().changeProductId(product.id)
      useCartStore().addToCart(item)
    },

    changeQuantityMixin(event, product) {
      let payload = {
        productId: product.id,
        quantity:
          parseInt(event.target.value) > product.inStock
            ? product.inStock
            : parseInt(event.target.value),
      };
      useCartStore().changeQuantityTo(payload)
    },

    addTocompareMixin(product) {
      let payload = {
        image: product.images[0].src,
        message: "Added To compare",
      };
    
      useCompareStore().addToCompare(product.id)
      useClickStore().toggleSuccessfulModal(payload)
    },

    isMobile() {
      const mobileDeviceRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
      const userAgent = navigator.userAgent;
    
      if (mobileDeviceRegex.test(userAgent)) {
        return true;
      }
    
      const isTouchCapable = ('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch);
      if (isTouchCapable) {
        return true;
      }
    
      const mql = window.matchMedia && window.matchMedia('(pointer:coarse)');
      if (mql && mql.media === '(pointer:coarse)' && mql.matches) {
        return true;
      }
    
      return false;
    },

    doPayIn(data) {
      return new Promise((resolve, reject) => {
        const info = this.getDeviceInfo()
        const payIn = new FormData()
        payIn.append('card_id', data.card)
        payIn.append('color_depth', info.color_depth)
        payIn.append('screen_height', info.screen_height)
        payIn.append('screen_width', info.screen_width)
        payIn.append('timezone_offset', info.timezone_offset)
        payIn.append('language', info.language)
        payIn.append('amount', data.amount)
        payIn.append('return_url', data.return_url)
    
        const loader = useNuxtApp().$loading.show()
        const payInPromise = useNuxtApp().$api.post('/mangopay/card/payIn', payIn)
          .then((res) => {
            if (res.data.payIn.SecureModeNeeded) {
              window.location.href = res.data.payIn.SecureModeRedirectURL
            } else if (res.data.payIn.Status === 'FAILED') {
              useNuxtApp().$toast.error(res.data.payIn.ResultMessage)
              reject(res.data.payIn.ResultMessage) // Reject the promise on failure
            } else {
              useNuxtApp().$toast.success('Pagamento completato con successo')
              resolve(res.data) // Resolve the promise on success
            }
          })
          .catch((error) => {
            useNuxtApp().$toast.error(error.response.data.errors)
            reject(error.response.data.errors) // Reject the promise on error
          })
          .finally(() => {
            loader.hide()
          });
    
        // Returning the promise so that the caller can handle further actions
        return payInPromise
      });
    },
    getDeviceInfo() {
			const deviceInfo = {
				screen_width: window.screen.width,
				screen_height: window.screen.height,
				color_depth: window.screen.colorDepth,
				timezone_offset: new Date().getTimezoneOffset(),
				language: navigator.language
			};
			
			return deviceInfo;
		}
  },
};
