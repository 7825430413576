import axiosData from "../static/data/cart.json";
import axiosAllProucts from "../static/data/products.json";
import { useClickStore } from "./clickEvents";
import { useLayout } from "./layout";
import { useProductStore } from "./products";
import { useAuthStore } from "./auth";
export const useCartStore = defineStore({
  id: "cart",
  state: () => {
    return {
      cartProduct: {},
      userInfo: {},
      coupon: null,
      order: []
    };
  },
  getters: {
    getCoupon(state) {
      return state.coupon
    },
    cartTotal(state) {
      console.log(state.cartProduct)
      let total = state.cartProduct.price;
      return Math.round(total, 2);
    },
    getCartItem(state) {
      return state.cartProduct
    }
  },
  actions: {
    addToCart(payload) {
      this.cartProduct = payload.product
    },
    
    
    clearAllCartItems() {
      this.cartProduct = null;
      this.coupon = null;
    },
    removeCartItem(payload) {
     state.clearAllCartItems() 
    },
    createOrder ( payload) {
      this.order.push(payload);
    },
    saveUserInfo ( payload)  {
      if(typeof payload === 'string' || payload instanceof String) {
        this.userInfo = JSON.parse(payload);
      } else {
        this.userInfo = payload
      }
    },
    setLocalCart(payload){
      this.cartProduct = payload
    },
    setCoupon(coupon) {
      this.coupon = coupon;
    }
  },
});
