import {constants} from "../static/const/index";
import globalMixin from '~/mixins/mixins'
import VueSlider from 'vue-slider-component'
import {VueImageZoomer} from 'vue-image-zoomer'
import PayPal from 'vue-paypal-checkout';

import Lightbox from 'vue-easy-lightbox'

import 'vue-image-zoomer/dist/style.css';

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.mixin(globalMixin)
    nuxtApp.provide('const', constants)
    nuxtApp.vueApp.component('VueSlider', VueSlider)
    nuxtApp.vueApp.component('VueImageZoomer', VueImageZoomer)
    nuxtApp.vueApp.component('paypal-checkout', PayPal)
    nuxtApp.vueApp.use(Lightbox);
})