export const constants = {
  comparePagePath: "/page/compare",
  cartPagePath: "/page/cart",
  wishlistPagePath: "/page/wishlist",
  CommonImagePath: "@/assets/images/",
  RawHamColorblockTshirt: "Raw Ham Colorblock T-shirt",
  Review: "5040 Review",
  Price: "$49.55",
  Size: "Size",
  Sizes: ["S", "M", "L", "XL", "XXL"],
  Color: "Colore",
  Black: "Nero",
  White: "Bianco",
  Blue: "Blu",
  Gray: "Grigio",
  Qty: "Quantità",
  Quantity: [1, 2, 3, 4],
  Addtocart: "Aggiungi al carrello",
  AddedToBag: "Aggiunto al carrello",
  sharewith: "Condividi con",
  Sale: "Saldi",
  NewTrandingFashion: "Nuova moda trendy",
  BUYONEGETONE: "ACQUISTA UNO PRENDINE UNO",
  FREE: "GRATIS",
  Discover: "Scopri",
  OurCollection: "Aggiunti di recente dai nostri utenti",
  OurCategory: "La nostra categoria",
  NewArrival: "Nuovi arrivi",
  Newoffers: "Nuove offerte",
  JustForYou: "Solo per te",
  InstagramShop: "Negozio Instagram",
  NewCollection: "Nuova collezione",
  LatestProduct: "Ultimi prodotti",
  OurProduct: "I nostri prodotti",
  Mostpopularss: "Più popolari",
  OurUpdate: "Le nostre notizie e aggiornamenti",
  OurBlog: "Il nostro blog",
  Buttons: "Pulsanti",
  SubscribeOurNews: "Iscriviti alle nostre news",
  SubscribeDescription: "Iscriviti e ricevi le nostre newsletter per seguire le novità sui nostri fantastici prodotti di scarpe.",
  ShopNow: "Acquista ora",
  NEW: "Nuovo",
  OFF: "Sconto",
  Next: "Avanti",
  Prev: "Indietro",
  HurryUp: "Affrettati!",
  SpecialOffer: "Offerta speciale",
  Showdetails: "Mostra dettagli",
  LearnMore: "Scopri di più",
  VRCollection: "Collezione VR",
  Share: "Condividi",
  BUYNOW: "ACQUISTA ADESSO",
  Scopri: "Scopri",
  Discount: "Sconto",
  FreshFruits: "Frutta fresca",
  OurNewsUpdate: "Le nostre notizie e aggiornamenti",
  ReadMore: "Leggi di più",
  Allcategories: "Tutte le categorie",
  CategoryList: "Lista delle categorie",
  pagenotfound: "pagina non trovata",
  pagedescription: "La pagina che stai cercando non esiste o si è verificato un altro errore. Torna indietro o dirigiti altrove per scegliere una nuova direzione.",
  BackHomePage: "Torna alla pagina iniziale",
  Logins: "Accesso",
  Username: "Nome utente",
  Pleasefillthename: "Inserisci il nome",
  Password: "Password",
  Forgotyourpassword: "Hai dimenticato la password?",
  LogIn: "Accedi",
  Orsigninwith: "O accedi con",
  Facebook: "Facebook",
  Google: "Google",
  Notamember: "Non sei ancora un membro?",
  Signupnow: "Iscriviti ora",
  Registers: "Registrazione",
  Name: "Nome",
  EmailAddress: "Indirizzo email",
  ConfirmPassword: "Conferma password",
  SignUp: "Registrati",
  Orsignupwith: "O registrati con",
  Alreadyhaveanaccount: "Hai già un account?",
  ForgotPassword: "Password dimenticata",
  EnterEmailAddress: "Inserisci l'indirizzo email",
  Send: "Invia",
  cartDescription: "Il tuo carrello scadrà tra",
  minutes: "minuti",
  CheckOut: "Procedi al pagamento",
  clearallitems: "Svuota tutti gli articoli",
  ContinueShopping: "Continua lo shopping",
  ApplyCoupon: "Applica coupon",
  CartTotals: "Totale carrello",
  TotalMRP: "Totale MRP",
  CouponDiscount: "Sconto coupon",
  ConvenienceFee: "Commissione di servizio",
  ProcessCheckout: "Procedi al pagamento",
  Promocode: "Codice promozionale",
  EXAMPLECODE: "CODICEESEMPIO",
  TotalUSD: "Totale (USD)",
  Yourcart: "Il tuo carrello",
  Country: "Paese",
  CountryArr: [
  "Stati Uniti",
  "India",
  "America",
  "Sud America",
  "Dubai",
  "Hong Kong",
  "Indonesia",
  "Pakistan",
  "Arabia Saudita",
  "Cina",
  ],
  Choose: "Scegli...",
  State: "Stato",
  StateArr: [
  "Gujarat",
  "Maharashtra",
  "Goa",
  "Delhi",
  "Madhya Pradesh",
  "Uttar Pradesh",
  "Himachal Pradesh",
  "Rajasthan",
  ],
  Payment: "Pagamento",
  FirstName: "Nome",
  LastName: "Cognome",
  SaveInfo: "Salva queste informazioni per la prossima volta",
  Creditcard: "Carta di credito",
  Debitcard: "Carta di debito",
  PayPal: "PayPal",
  Nameoncard: "Nome sulla carta",
  Fullname: "Nome completo come appare sulla carta",
  Creditnumber: "Numero di carta di credito",
  Creditnumberrequired: "È necessario inserire il numero di carta di credito",
  Expiration: "Scadenza",
  CVV: "CVV",
  Continuecheckout: "Paga ora",
  Wearecomingsoon: "Stiamo arrivando presto",
  ComingDescription: "Siamo quasi pronti! Se vuoi essere avvisato quando il sito web sarà online, iscriviti alla nostra lista di invio!",
  NotifyMe: "Avvisami!",
  Address: "Indirizzo:",
  ActualAddress: "Riv1418erwood Drive, Suite 3245 Cottonwood, CA 96052, Stati Uniti",
  PhoneNumber: "Numero di telefono:",
  num1: "+185659635",
  num2: "+658651167",
  ThemeEmail: "acktag@gmail.com",
  ThemeEmail2: "acktag@gmail.com",
  LetTouch: "Restiamo in contatto",
  SuggestionDesc: "Siamo aperti a qualsiasi suggerimento o anche solo per fare una chiacchierata",
  Submit: "Invia",
  ConfirmEmail: "Conferma email",
  Comment: "Commento",
  Email: "Email",
  EmailRequired: "Il tuo indirizzo email non verrà pubblicato. I campi obbligatori sono contrassegnati *",
  Contactus: "Contattaci",
  Howhelp: "Come possiamo aiutarti?",
  OrderSuccess: "Ordine completato",
  PaymentDescription: "Il pagamento è stato processato con successo e il tuo ordine è in viaggio",
  TransactionID: "ID transazione: 267676GHERT105467",
  OrderDetail: "Dettagli ordine",
  summery: "sommario",
  OrderID: "ID ordine: 5563853658932",
  OrderDate: "Data ordine: 22 ottobre 2018",
  OrderTotal: "Totale ordine: $907.28",
  shippingaddress: "indirizzo di spedizione",
  Add1: "Gerg Harvell",
  Add2: "568, Suite Ave.",
  Add3: "Australia, 235153 Numero di contatto 48465465465",
  paymentmethod: "Metodo di pagamento",
  PayonDelivery: "Paga alla consegna (contanti/carta). Pagamento in contanti alla consegna disponibile. L'accettazione di carte/bonifico bancario è soggetta alla disponibilità del dispositivo.",
  expecteddate: "data prevista di consegna:",
  fulldate: "22 ottobre 2018",
  trackorder: "traccia ordine",
  SearchForProducts: "Cerca prodotti",
  ShowMenu: "Mostra menu",
  Category: "Categoria",
  PopularPosts: "Articoli popolari",
  Johnwick: "John Wick",
  date: "15 agosto 2021",
  LeaveComments: "Lascia commenti",
  Comments: "Commenti",
  Blogtitle: "Solo un post di formato standard.",
  BlogDesp1: "Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato.",
  BlogDesp2: "A differenza di quanto si pensi, Lorem Ipsum non è semplicemente una sequenza casuale di caratteri. Risale ad un classico della letteratura latina del 45 a.C., cosa che lo rende vecchio di 2000 anni. Richard McClintock, professore di latino al Hampden-Sydney College in Virginia, ha ricercato una delle più oscure parole latine, consectetur, da un passaggio del Lorem Ipsum e ha scoperto tra i vari testi in cui è citata, la fonte da cui è tratto il testo, le sezioni 1.10.32 e 1.10.33 del De Finibus Bonorum et Malorum di Cicerone. Questo libro è un trattato su teorie di etica molto popolare nel Rinascimento. La prima riga del Lorem Ipsum, \"Lorem ipsum dolor sit amet...\", è tratta da un passaggio della sezione 1.10.32.",
  BlogDesp3: "È ormai consodato che un lettore che osserva il layout di una pagina viene distratto dal contenuto testuale che la pagina contiene. Lo scopo dell'utilizzo del Lorem Ipsum è che offre una normale distribuzione delle lettere (al contrario di quanto avviene se si utilizzano brevi frasi ripetute, ad esempio \"testo qui\"), apparendo come un normale blocco di testo leggibile. Molti software di impaginazione e di web design utilizzano Lorem Ipsum come testo modello. Molte versioni del testo sono state prodotte negli anni, a volte casualmente, a volte di proposito (ad esempio inserendo passaggi ironici).",
  loadmore: "Carica altro",
  NoProduct: "Nessun altro prodotto",
  ElementButton: "Pulsante elemento",
  ButtontextSizes: "Dimensioni testo pulsante",
  Bootstrapbutton: "Pulsante Bootstrap",
  ButtonOutlines: "Bordo del pulsante",
  Blocklevelbutton: "Pulsante di livello blocco",
  Buttonblock: "Blocco del pulsante",
  Activelink: "Link attivo",
  Links: "Link",
  Buttongroups: "Gruppo di pulsanti",
  Category1: "Stile categoria 1",
  Category2: "Stile categoria 2",
  Category3: "Stile categoria 3",
  Collection1: "Collezione Banner 1",
  Collection2: "Collezione Banner 2",
  Collection3: "Collezione Banner 3",
  Collection4: "Collezione Banner 4",
  Collection5: "Collezione Banner 5",
  Collection6: "Collezione Banner 6",
  Collection7: "Collezione Banner 7",
  Deal1: "Offerta Banner 1",
  Deal2: "Offerta Banner 2",
  Deal3: "Offerta Banner 3",
  Deal4: "Offerta Banner 4",
  Product1: "Stile prodotto 1",
  Product2: "Stile prodotto 2",
  Product3: "Stile prodotto 3",
  Product4: "Stile prodotto 4",
  Product5: "Stile prodotto 5",
  Product6: "Stile prodotto 6",
  All: "Tutti",
  App: "App",
  Card: "Carta",
  Web: "Web",
  ShopTheLatestTrends: "Acquista le ultime tendenze",
  shopdescription: "Acquista le ultime tendenze dell'abbigliamento con la nostra selezione settimanale delle novità online su Voxo. Dalla nostra ultima collezione donna.",
  bannerdescription: "Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato.",
  Filter: "Filtra",
  Close: "Chiudi",
  HideFilter: "Nascondi filtro",
  ShowFilter: "Mostra filtro",
  Latestfilter: "Ultimo filtro",
  Brand: "Marca",
  SpecifyDescription: "Il modello indossa una camicia bianca della nostra collezione stilistica, consulta l'immagine per una simulazione di come apparirebbe effettivamente la camicia. Sulla camicia è scritto un testo in corsivo nero che si adatta bene al colore bianco.",
  ProductDescription1: "Ancora una volta, nessuno ama o cerca di ottenere il dolore stesso, perché è dolore, ma perché talvolta si verificano circostanze in cui la fatica e il dolore possono procurare un grande piacere. Per fare un esempio banale, chi di noi si sottopone a un esercizio fisico faticoso, se non per ottenere qualche vantaggio? Ma chi ha il diritto di recriminare un uomo che sceglie di godersi un piacere che non ha conseguenze fastidiose o di evitare un dolore che non produce alcun piacere risultante?",
  ProductQuestion1: "Fornisci una descrizione completa del sistema",
  fabric: "Tessuto",
  ProductDescription2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum purus at sem volutpat, ac finibus velit sollicitudin. Maecenas condimentum efficitur nulla in tincidunt. Nullam ullamcorper neque vel mi suscipit feugiat. Sed ut lorem et ante fringilla consectetur. Aenean placerat auctor ultrices. Vestibulum id mi sit amet mi efficitur pellentesque nec at lacus. Sed facilisis a urna id pellentesque. Vestibulum id lorem tristique, luctus lectus at, viverra neque.",
  ProductDescription3: "La seta artificiale è prodotta con fibre sintetiche come il rayon. È leggera e morbida sulla pelle per un comfort estivo. La seta artificiale è prodotta con fibre sintetiche come il rayon. È leggera e morbida sulla pelle per un comfort estivo.",
  ProductDescription4: "Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo.",
  Customerreviews: "Recensioni dei clienti",
  numRatings: "82 recensioni",
  Rating: "Valutazione",
  CustomersAlsoBoughtThese: "I clienti hanno acquistato anche questi prodotti",
  LTR: "LTR",
  RTL: "RTL",
  Prices: "Prezzi",
  DiscountRange: "Gamma di sconti",
  VoxoPlus: "VOXO PLUS",
  EnjoyExperience: "Goditi un'esperienza simile a un'app",
  EnjoyExperienceDescription: "Con questa opzione di schermo, puoi utilizzare il sito web come un'app.",
  ADDTOHOMESCREEN: "AGGIUNGI ALLA SCHERMATA HOME",
  wishlist: "Lista dei desideri",
  VIEWCART: "VISUALIZZA CARRELLO",
  English: "Inglese",
  Franch: "Francese",
  Spanish: "Spagnolo",
  ProceedTOPayment: "Procedi al pagamento",
  Total: "Totale",
  SuccessAddtocart: "aggiunto con successo al carrello.",
  TOTAL: "TOTALE",
  orderIn: "ordini nelle ultime 24 ore",
  activeView: "visualizzazione attiva",
  ProductName: "Nuova maglietta di marca",
  BestSeller: "Best seller #1",
  infashion: "alla moda",
  selectsize: "seleziona la taglia",
  sizechart: "tabella delle taglie",
  pleaseselectsize: "seleziona una taglia",
  quentityname: "quantità",
  Days: "Giorni",
  Hour: "Ore",
  Min: "Min",
  Sec: "Sec",
  productdetail: "dettagli del prodotto",
  Tags: "Tag",
  Viewdetails: "Visualizza dettagli",
  CustomerRating: "Valutazione del cliente",
  GenericName: "Nome generico",
  Department: "Reparto",
  Manufacturer: "Produttore",
  DateFirstAvailable: "Data di prima disponibilità",
  image: "immagine",
  productname: "nome del prodotto",
  price: "prezzo",
  availability: "disponibilità",
  action: "azione",
  whoAreWe: "CHI SIAMO",
  largerFashion: "largest Online fashion destination",
  aboutUsDesc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam,
    culpa! Asperiores labore amet nemo ullam odit atque adipisci,
    hic, aliquid animi fugiat praesentium quidem. Perspiciatis,
    expedita!`,
  aboutUsDesc2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam,
    culpa! Asperiores labore amet nemo ullam odit atque adipisci,
    hic, aliquid animi fugiat praesentium quidem. Perspiciatis,
    expedita!`,
  meetOurTeam: "Meet Our Team",
  teamDesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero assumenda hic porro odio voluptas qui quod sed.",
};