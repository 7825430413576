import { useLocalStorage } from "@vueuse/core";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { useCartStore } from "./cart";
import { useProductStore } from "./products";
export const useAuthStore = defineStore({
  id: "Auth",
  state: () => {
    return {
      user: useLocalStorage('user', {}),
      token: useLocalStorage('token', null),
      isLoggedIn: useLocalStorage('isLoggedIn', false),
      unreadCount: useLocalStorage('unreadCount', 0)
    };
  },
  getters: {
    isSeller() {
      if(this.user.roles.find((x) => x.category == 'SELLER')) {
        return true
      } else {
        return false
      }
    },
    hasMangopay() {
      if(this.user.hasMangopay) {
        return true
      } else {
        return false
      }
    }
  },
  actions: {
    login(user, pass) {
        
        return useNuxtApp().$api
            .post(useRuntimeConfig().public.baseURL + '/api/auth/login', {
                email: user,
                password: pass,
            })
            
    },
    logout() {
      this.user = this.token = null
      this.isLoggedIn = false
      useCartStore().clearAllCartItems()
      useProductStore().storeWishlist = []
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('isLoggedIn')
    },
    setUser(user, token) {
      if(typeof user == 'string') {
        user = JSON.parse(user)
      }
      this.user = user
      this.token = token
      this.isLoggedIn = true
    },
    updateUser(user) {
      if(typeof user == 'string') {
        user = JSON.parse(user)
      }
      this.user = {...user}
    }
  }
});
