import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

export default defineNuxtPlugin((nuxtApp) => {
  const toast = useToast()
  return {
      provide: {
        toast: toast,
      },
    };
});