import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/src/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/src/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_yfWm7jX06p from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/src/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_plugins_js_1ipSzs5GBa from "/src/plugins/plugins.js";
import plugins_axios_js_sVCuMR6hEC from "/src/plugins/axios.js";
import plugins_loading_js_JsMeH12IfP from "/src/plugins/loading.js";
import plugins_social_sharing_js_iQgTMDG1bs from "/src/plugins/social-sharing.js";
import plugins_ssrPlugins_js_cEKV0TUavU from "/src/plugins/ssrPlugins.js";
import plugins_toast_js_Zl2rZYKMaV from "/src/plugins/toast.js";
import plugins_useBootstrap_client_ts_ouDJvcVteA from "/src/plugins/useBootstrap.client.ts";
import plugins_veevalidate_components_js_ipnjB2Q1ls from "/src/plugins/veevalidate-components.js";
import plugins_veevalidate_rules_js_e9Gp0lCERk from "/src/plugins/veevalidate-rules.js";
import plugins_vue_masonry_wall_ts_13R6vu58Yn from "/src/plugins/vue-masonry-wall.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxtjs_i18n_dist_runtime_plugins_i18n_mjs_yfWm7jX06p,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_plugins_js_1ipSzs5GBa,
  plugins_axios_js_sVCuMR6hEC,
  plugins_loading_js_JsMeH12IfP,
  plugins_social_sharing_js_iQgTMDG1bs,
  plugins_ssrPlugins_js_cEKV0TUavU,
  plugins_toast_js_Zl2rZYKMaV,
  plugins_useBootstrap_client_ts_ouDJvcVteA,
  plugins_veevalidate_components_js_ipnjB2Q1ls,
  plugins_veevalidate_rules_js_e9Gp0lCERk,
  plugins_vue_masonry_wall_ts_13R6vu58Yn
]