import { useLocalStorage } from "@vueuse/core";
import axiosData from "../static/data/products.json";
import { useLayout } from "./layout";
import { useAuthStore } from "./auth";

export const useProductStore = defineStore({
  id: "product",
  state: () => {
    return {
      originalProducts: axiosData.data,
      data: useLocalStorage('productList', []),
      colorOptions: useLocalStorage('colorOptions', []),
      axiosProducts: [],
      storeWishlist: useLocalStorage('storeWishlist', []),
    };
  },
  getters: {
    wishlist(state) {
      return state.storeWishlist;
    },
  },
  actions: {
    initProducts() {
      this.fetchProducts().then((res) => {
        this.setProductsData(res.data.rows)
        this.colorOptions = res.data.frontendColorOptions
      })
    },
    fetchProducts() {
      return useNuxtApp().$api.get('/products/getProducts')
    },
    initWishlist() {
      return useNuxtApp().$api.get('/products/getWishlist')
    },
    setProductsData(payload) {
      this.data = payload;
    },
    async addToWishlist(payload) {
      if (isPresentInWishlist(this.storeWishlist, payload)) return true;
      else {
        this.storeWishlist.push(payload);
        return false;
      }
    },
    removeFromWishlist(payload) {
      const auth = useAuthStore()
      if(auth.isLoggedIn) {
        useNuxtApp().$api.post(`/products/removeWishlist/${payload.id}`).then((res) => {
          this.storeWishlist.every((item, index) => {
            if (item.id === payload.id) {
              this.storeWishlist.splice(index, 1);
              localStorage.setItem("wishlistItems", JSON.stringify(this.storeWishlist));
              return false;
            } else return true;
          });
        }).catch((error) => {
          useNuxtApp().$toast.error(error.response.data.errors)
        })
      } else {
        this.storeWishlist.every((item, index) => {
          if (item.id === payload.id) {
            this.storeWishlist.splice(index, 1);
            localStorage.setItem("wishlistItems", JSON.stringify(this.storeWishlist));
            return false;
          } else return true;
        });
      }
    },
    currencyChanged() {
      let dataToSet = [];
      axiosData.data.forEach((item, index) => {
        dataToSet.push({
          ...axiosData.data[index],
        });
      });
      dataToSet.forEach((values) => {
        values.price = (
          values.price * useLayout().currencyConversionMultiple
        ).toFixed(2);
      });

      this.data = dataToSet;
    },
    setLocalWishlist(payload){
      this.storeWishlist = [...payload]
    }
  },
});

function isPresentInWishlist(wishl, productToAdd) {
  let flag = false;
  wishl.every((item) => {
    if (item.id === productToAdd.id) {
      flag = true;
      return false;
    } else return true;
  });
  return flag;
}
