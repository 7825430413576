import { configure, defineRule } from 'vee-validate'
// import { required, email, min } from '@vee-validate/rules';
import * as rules from "@vee-validate/rules";
import { localize, loadLocaleFromURL } from "@vee-validate/i18n"
export default defineNuxtPlugin((nuxtApp) => {
  
  // defineRule('required', required);
  // defineRule('email', email);
  // defineRule('min', min);
  Object.keys(rules)
    .filter((k) => k !== "default")
    .forEach((rule) => {
      defineRule(rule, rules[rule]);
    //   console.log(rule);
    });

    configure({
      generateMessage: localize('it', {
        messages: {
          alpha: "Il campo {field} può contenere solo caratteri alfabetici",
          alpha_num: "Il campo {field} può contenere solo caratteri alfanumerici",
          alpha_dash: "Il campo {field} può contenere caratteri alfa-numerici così come lineette e trattini di sottolineatura",
          alpha_spaces: "Il campo {field} può contenere solo caratteri alfanumerici così come spazi",
          between: "Il campo {field} deve essere compreso tra 0:{min} e 1:{max}",
          confirmed: "Il campo {field} non corrisponde",
          digits: "Il campo {field} deve essere numerico e contenere esattamente 0:{length} cifre",
          dimensions: "Il campo {field} deve essere 0:{width} x 1:{height}",
          email: "Il campo {field} deve essere un indirizzo email valido",
          excluded: "Il campo {field} deve avere un valore valido",
          ext: "Il campo {field} deve essere un file valido",
          image: "Il campo {field} deve essere un'immagine",
          integer: "Il campo {field} deve essere un numero",
          is_not: "Il campo {field} non è valido",
          length: "La lunghezza del campo {field} deve essere 0:{length}",
          max_value: "Il campo {field} deve essere minore o uguale a 0:{max}",
          max: "Il campo {field} non può essere più lungo di 0:{length} caratteri",
          mimes: "Il campo {field} deve avere un tipo di file valido",
          min_value: "Il campo {field} deve essere maggiore o uguale a 0:{min}",
          min: "Il campo {field} deve avere almeno 0:{length} caratteri",
          numeric: "Il campo {field} può contenere solo caratteri numerici",
          one_of: "Il campo {field} deve avere un valore valido",
          regex: "Il campo {field} non ha un formato valido",
          required: "Il campo {field} è richiesto",
          required_if: "Il campo {field} è richiesto",
          size: "Il campo {field} deve essere inferiore a 0:{size}KB"
        }
      }),
    })
});